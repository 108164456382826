$(document).ready(() => {
  $('#congresses_users_register_professional_group, #congresses_users_insert_professional_group, #user_professional_group_id').change(function(e) {
    var professional_group = parseInt($(this).children('option:selected').val())
    const doctor = 1, nurse = 2, pharmacist = 3

    if (professional_group == doctor) {
      $('.block_specializations').show()
    } else {
      $('.block_specializations').hide()
    }

    if ( [doctor, nurse, pharmacist].includes(professional_group) ){
      $('.block_npwz').show()
    } else {
      $('.block_npwz').hide()
    }
  });
});
