import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';
import MarketProducts from '../marketplace/Products';

const Products = ({products}) => {
  if (isEmpty(products)) return null;

  return (
    <motion.div
      layout
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className="sponsor--products bg-white">
        <h4 className="text-center py-4">Strefa zakupów</h4>
        <ul className="list-unstyled d-flex flex-columns flex-wrap justify-content-lg-center px-5">
          <MarketProducts products={products}/>
        </ul>
      </div>
    </motion.div>
  )
}

export default Products;
