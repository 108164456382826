import React from 'react'
import { makeObservable, action, runInAction} from 'mobx'
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty, filter, values} from 'lodash';

dayjs.extend(isBetween);

export class WorkshopsStore {
  rootStore
  workshops
  currentWorkshops

  constructor(rootStore) {
    makeObservable(this, {
      init: action
    });
    this.rootStore = rootStore;
  }

  init(workshops) {
    this.workshops = workshops
    this.setCurrentWorkshops()
  }

  setCurrentWorkshops() {
    if (isEmpty(this.workshops)) return false;

    runInAction(() => {
      this.currentWorkshops = filter(values(this.workshops), workshop => this.rootStore.appointmentsStore.date.isBetween(workshop.start_time, workshop.finish_time));
    })
  }

  watchNow() {
    return !isEmpty(this.currentWorkshops);
  }
}
