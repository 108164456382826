import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import apiFetch from '../utils/apiFetch';
import play from "../../../images/icons/play.svg";
import WatchNowPreloader from './WatchNowPreloader';
import formatDate from '../utils/formatDate';

const WatchNowEvents = ({congressSlug, sectionSlug, currentWatchNowTab, className}) => {
  const [currentAppointmentsError, setCurrentAppointmentsError] = useState(false);
  const [currentAppointments, setCurrentAppointments] = useState({});

  useEffect(() => {
    apiFetch(`${congressSlug}/current_appointments`, setCurrentAppointmentsError, setCurrentAppointments);
  }, []);

  const buildEntries = () => currentWatchNowTab === 'appointments' ? buildAppointments() : buildWorkshops();

  const buildAppointments = () => {
    return currentAppointments?.appointments.map(appointment => {
      return (<li className={clsx(className)} key={appointment.id}>
        <p>
          {appointment.title}
        </p>
        <p className="text-nowrap time d-block mt-2">{appointment.start_time} - {appointment.finish_time}</p>
        <p>
          <a className="btn btn-grey-outline btn-block" href={`/${congressSlug}/${sectionSlug}/appointment`}>
            <img src={play} className="play mr-2 pb-1" alt={appointment.title}/>
            Oglądaj</a>
        </p>
      </li>)
    })
  }

  const buildWorkshops = () => {
    return currentAppointments?.workshops.map(workshop => {
      return (<li className={clsx(className)} key={workshop.id}>
        <p>
          {workshop.title}
        </p>
        <p className="text-nowrap time d-block mt-2">{formatDate(workshop.start_time, 'HH:mm')} - {formatDate(workshop.finish_time, 'HH:mm')}</p>
        <p>
          <a className="btn btn-grey-outline btn-block" href={`/${congressSlug}/${sectionSlug}/workshops/${workshop.slug}`}>
            <img src={play} className="play mr-2 pb-1" alt={workshop.title}/>
            Oglądaj</a>
        </p>
      </li>)
    })
  }


  return (<Fragment>
    {isEmpty(currentAppointments[currentWatchNowTab]) ? <>
      <li className={clsx(className)}><WatchNowPreloader/></li>
      <li className={clsx(className)}><WatchNowPreloader/></li>
    </> : buildEntries()}
  </Fragment>);
}

export default WatchNowEvents;
