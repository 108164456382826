import React, { Fragment } from 'react';
import { isEmpty, values } from 'lodash';
import clsx from 'clsx';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { observer } from 'mobx-react-lite';
import { useAppointmentsStore } from '../rootStoreProvider';

const CongressDays = observer(({agendaStore}) => {
  const store = useAppointmentsStore();

  const congressDays = () => {
    if (isEmpty(store.congressDays) || isEmpty(agendaStore.congressDay)) return null;

    return store.congressDays.map((day, index) =>
      <li className={clsx('congress-days--item mr-4 pb-lg-2', agendaStore.congressDay?.id === day.id && 'active')} key={day.id}
          onClick={() => agendaStore.setCongressDay(day)}>
        <strong className="d-block">Dzień {index + 1}</strong>
        <span
          className="congress-days--item--date">{dayjs(day.event_date).locale('pl').format('dddd, D MMMM YYYY')}</span>
      </li>
    );
  }

  return (
    <Fragment>
      {congressDays()}
    </Fragment>
  );
})

export default CongressDays;
