import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import apiFetch from '../utils/apiFetch';

const Contests = () => {
  const {congressSlug} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const [hasError, setError] = useState(false);
  const [contestList, setContestList] = useState({});

  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/contests`, setError, setContestList);
  }, []);

  const buildEntries = () => {
    if (isEmpty(contestList)) return null;

    return contestList.map(contest => (
      <div key={contest.id} className="light-bg mb-3 py-4 p-x5">
        <div className=" d-flex flex-md-row flex-column justify-content-between align-items-center ">
          <div className="logo-wrapper p-3">
            <img src={contest.avatar} alt={contest.title}/>
          </div>
          <div className="description-wrapper px-3">
            <h4>{contest.title}</h4>
            <p className="contests--description">
              {contest.description}
            </p>
            <p className="contests--lead">{contest.lead}</p>
          </div>
          <div className="p-3">
            <a href={contest.external_url} target="_blank" className="btn btn-grey-outline d-block">Weź udział</a>
            {contest.regulations &&
            <a href={contest.regulations} target="_blank" className="btn btn-grey-outline d-block mt-2">Regulamin</a>}
          </div>
        </div>
      </div>
    ))
  };

  return (
    <Fragment>
      <Header congressSlug={congressSlug}/>
      <div className="contests">
        <h2 className="text-center light-text my-3 my-md-5">Konkursy</h2>
        <div className="container">
          {buildEntries()}
        </div>
      </div>
      <HorizontalSponsors/>
    </Fragment>
  );
}

export default Contests;
