import './jquery.dropdown.min';

(function() {
  window.Helpers = (function() {

    function Helpers() {}

    var $indicator = '<div class="indicator-layer"></div>';

    Helpers.appendIndicator = function(element) {
      return $(element).append($indicator);
    };

    Helpers.removeIndicator = function(element) {
      return $(element).find('[class*="indicator"]').remove();
    };

    Helpers.activateMultiselectSearch = function(element) {
      return $(element).find('.multiselect-search').each(function() {
        $( this ).dropdown({
          multipleMode:'label',
          input:'<input type="text" maxLength="20" placeholder="Szukaj">'
        });
      });
    };

    return Helpers;

  })();

}).call(this);
