import { useState, useEffect } from 'react';
import apiFetch from '../utils/apiFetch';

const useAppointmentGroupsEffect = (congressSlug, sectionSlug) => {
  const [hasError, setError] = useState(false);
  const [appointmentGroups, setAppointmentGroups] = useState({});

  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/appointment_groups`, setError, setAppointmentGroups);
  }, [sectionSlug]);

  return appointmentGroups;
}

export default useAppointmentGroupsEffect;
