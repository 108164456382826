import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import isMobile from '../utils/isMobile';

const Ads = ({desktopId, mobileId, className}) => {
  const place = isMobile() ? mobileId : desktopId;
  if (!place) return null;

  useEffect(() => {
    sas.cmd.push(function () {
      sas.call('std', {
          siteId: 369667,
          pageId: 1302704,
          formatId: place,
          tagId: `sas_${place}`,
          target: 'url=' + window.location.href.split('?')[0]
        }
      );
    });
  }, []);

  return <Fragment>
    <div className={clsx(className)} id={`sas_${place}`}/>
  </Fragment>
};

Ads.defaultProps = {
  mobileId: null,
  desktopId: null,
  className: null
}

export default Ads;
