import React, { Fragment, useContext } from 'react';
import keys from 'lodash/keys';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import CongressContext from '../CongressContext';
import CurrentAppointment from '../appointments/CurrentAppointment';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import MenuTiles from '../shared/MenuTiles';
import Header from "../shared/Header";

const Section = () => {
  const { congress, sections, congressSlug } = useContext(CongressContext);
  const { sectionSlug } = useParams();

  const sectionList = () => (
    <ul className="sections list-unstyled my-md-5">
      {keys(sections).map((slug) =>
        <li className={clsx('sections--item', slug === sectionSlug && 'active')} key={slug}>
          <Link className="sections--link" to={`/${congressSlug}/${slug}`}>{sections[slug].details.title}</Link>
        </li>)}
    </ul>
  );

  return (
    <Fragment>
    <Header congressSlug={congressSlug} />
      <div className="main-congress light-text">
        <div className="d-md-flex justify-content-md-between mt-md-5 no-gutters">
          <div className="congress-wrapper px-5">
            <h2 className="h3 mt-5 pt-5">
              <strong>{congress.title}</strong>
            </h2>
            <h1 className="h3">{congress.excerpt}</h1>
            {sectionList()}
            <CurrentAppointment />
          </div>
          <MenuTiles congressSlug={congressSlug} />
        </div>
        <HorizontalSponsors />
      </div>
    </Fragment>
  );
}

export default Section;
