import React, { Fragment, useContext } from 'react';
import { isEmpty, values, uniq } from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import CongressContext from '../CongressContext';
import formatDate from '../utils/formatDate';

const Workshops = () => {
  const {workshops} = useContext(CongressContext);
  const workshopsList = values(workshops);

  const getDates = () => {
    return uniq(workshopsList.map(workshop => formatDate(workshop.start_time, 'YYYY-MM-DD')))
  }

  const findWorkshopsByDate = date => {
    return workshopsList.map(workshop => {
      if (dayjs(date).isSame(workshop.start_time, 'day')) {
        return (<dl key={workshop.id} className="appointments--agenda--appointment p-3 d-flex flex-row">
          <dt
            className="mr-3">{formatDate(workshop.start_time, 'HH:mm')} - {formatDate(workshop.finish_time, 'HH:mm')}</dt>
          <dd>
            {workshop.title}
            {speaker(workshop)}
          </dd>
        </dl>);
      }
    })
  }

  const speaker = (workshop) => {
    if (isEmpty(workshop.speakers)) return null;

    return <div
      className="appointments--agenda--appointment--speaker d-flex flex-row align-items-start">
      <ul className="list-unstyled mb-0">
        {workshop.speakers.map((speaker, index) => (
          <li key={index}>{speaker.title} {speaker.first_name} {speaker.last_name}</li>))}
      </ul>
    </div>;
  }

  const workshopsListing = () => {
    if (isEmpty(workshops)) return null;

    return getDates().map((date, index) => {
      return <Fragment key={index}>
        <h4 className="h5 pb-3 mb-3" id={`entry_${index}`}>{date}</h4>
        {findWorkshopsByDate(date)}
      </Fragment>
    })
  }

  const subMenuDates = () => {
    if (getDates().length === 1) return null;

    return (<ul className="list-unstyled d-flex flex-row">
      {getDates().map((date, index) => <li className="pr-5" key={date}><a href={`#entry_${index}`}
                                                                          className="appointments--appointment-groups--link">{formatDate(date, 'D MMMM YYYY')}</a>
      </li>)}
    </ul>)
  }

  return (
    <Fragment>
      <h6 className="font-weight-bold">Warsztaty</h6>
      {subMenuDates()}
      {workshopsListing()}


    </Fragment>
  );
}

export default Workshops;
