import React from 'react';
import { motion } from 'framer-motion';

const Video = ({sponsorDetails}) => {

  return (<motion.div
    layout
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
  >
    <div className="container light-text">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1">
          <h3 className="text-center">Obejrzyj wideo</h3>
          <div dangerouslySetInnerHTML={{__html: sponsorDetails.sponsor.video_embed}}/>
        </div>
      </div>
    </div>
  </motion.div>);
}

export default Video;
