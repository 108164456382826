import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import TagManager from 'react-gtm-module'
import CongressContext from './CongressContext';
import Sponsors from './sponsors/Sponsors';
import Sponsor from './sponsors/Sponsor';
import Agenda from './agenda/Agenda';
import Congress from './congress/Congress';
import Appointment from './section/Appointment';
import Contests from './contests/Contests';
import Contact from './contact/Contact';
import Workshop from './workshops/Workshop';
import Workshops from './workshops/Workshops';
import MarketPlace from './marketplace/MarketPlace';
import { RootStoreProvider } from './rootStoreProvider'

const tagManagerArgs = {
  gtmId: 'GTM-KMH3ZL3',
  dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs);

const App = props => {
  const history = createBrowserHistory();
  history.listen(location => {
    const dataLayer = {
      dataLayer: {
        page: location.pathname
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(dataLayer);
  });
  const prefixPath = `/${props.congressSlug}/:sectionSlug`;

  return (
    <RootStoreProvider>
      <CongressContext.Provider value={props}>
        <Router history={history}>
          <Switch>
            <Route path={`${prefixPath}/workshops/:workshopSlug`} component={Workshop}/>
            <Route path={`${prefixPath}/workshops`} component={Workshops}/>
            <Route path={`${prefixPath}/sponsors/:sponsorSlug`} component={Sponsor}/>
            <Route path={`${prefixPath}/sponsors`} component={Sponsors}/>
            <Route path={`${prefixPath}/contact`} component={Contact}/>
            <Route path={`${prefixPath}/agenda`} component={Agenda}/>
            <Route path={`${prefixPath}/contests`} component={Contests}/>
            <Route path={`${prefixPath}/marketplace`} component={MarketPlace}/>
            <Route path={`${prefixPath}/appointment`} component={Appointment}/>
            <Route exact path={prefixPath} component={Congress}/>
          </Switch>
        </Router>
      </CongressContext.Provider>
    </RootStoreProvider>
  )
};
export default App;
