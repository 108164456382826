import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import IconSponsors from "../../../images/icons/ico-sponsors.svg";
import IconAgenda from "../../../images/icons/ico-agenda.svg";
import IconWorkshops from "../../../images/icons/ico-workshops.svg";
import IconCompetitions from "../../../images/icons/ico-competitions.svg";
import IconHelp from "../../../images/icons/ico-help.svg";
import '../../../stylesheets/congress_nav.scss';

const MenuTiles = ({congressSlug, hasContests, hasWorkshops}) => {
  const {sectionSlug} = useParams();
  const history = useHistory();
  const prefixUrl = `/${congressSlug}/${sectionSlug}`;
  const sponsorsUrl = `${prefixUrl}/sponsors`;
  const agendaUrl = `${prefixUrl}/agenda`;
  const workshopsUrl = `${prefixUrl}/workshops`;
  const contestsUrl = `${prefixUrl}/contests`;
  const contactUrl = `${prefixUrl}/contact`;

  return (
    <div className="congress-nav-wrapper mt-5 pb-5 pt-5 pt-md-0 mt-md-0 pr-md-5">
      <ul className="congress-nav list-unstyled px-5 px-md-0">
        <li className="congress-nav--item d-flex justify-content-between align-items-start px-4 py-4 mb-2"
            onClick={() => history.push(sponsorsUrl)}>
          <div>
            <Link className="navigation-link active" to={sponsorsUrl} data-kongres={congressSlug}>Wystawcy</Link>
            <Link className="btn btn-outline-light d-block mt-3" to={sponsorsUrl} data-kongres={congressSlug}>Odwiedź stoiska</Link>
          </div>
          <img src={IconSponsors} alt="Wystawcy"/>
        </li>
        <li className="congress-nav--item d-flex justify-content-between align-items-center px-4 py-4 mb-2"
            onClick={() => history.push(agendaUrl)}>
          <Link className="navigation-link" to={agendaUrl} data-kongres={congressSlug}>Program</Link>
          <img src={IconAgenda} alt="Program"/>
        </li>
        {hasWorkshops &&
        <li className="congress-nav--item d-flex justify-content-between align-items-center px-4 py-4 mb-2"
            onClick={() => history.push(workshopsUrl)}>
          <Link className="navigation-link" to={workshopsUrl} data-kongres={congressSlug}>Warsztaty</Link>
          <img src={IconWorkshops} alt="Warsztaty"/>
        </li>
        }
        {hasContests &&
        <li className="congress-nav--item d-flex justify-content-between align-items-center px-4 py-4 mb-2"
            onClick={() => history.push(contestsUrl)}>
          <Link className="navigation-link active" to={contestsUrl} data-kongres={congressSlug}>Konkursy</Link>
          <img src={IconCompetitions} alt="Konkursy"/>
        </li>
        }
        <li className="congress-nav--item d-flex justify-content-between align-items-center px-4 py-4"
            onClick={() => history.push(contactUrl)}>
          <Link className="navigation-link active" to={contactUrl} data-kongres={congressSlug}>
            Punkt informacyjny
          </Link>
          <img src={IconHelp} alt="Punkt Informacyjny"/>
        </li>
      </ul>
    </div>
  );
}

export default MenuTiles;
