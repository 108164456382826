import React, { createContext, useContext } from 'react';
import RootStore from './stores/RootStore';

let store;
const StoreContext = createContext(undefined);

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
}

export function useAppointmentsStore() {
  const {appointmentsStore} = useRootStore();
  return appointmentsStore;
}

export function useWorkshopsStore() {
  const {workshopsStore} = useRootStore();
  return workshopsStore;
}

export function useAgendaStore() {
  const {agendaStore} = useRootStore();
  return agendaStore;
}

export function RootStoreProvider({children}) {
  const root = store || new RootStore();
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}
