import React from 'react';
import { InlineWidget } from 'react-calendly';
import { motion } from 'framer-motion';

const Calendar = ({sponsorDetails, textStyles}) => {
  return (<motion.div
      layout
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div id="calendar" className="full-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 text-center text-lg-left" style={textStyles}>
              <h3 className="h4 d-lg-block mb-4">Zaplanuj spotkanie z naszym przedstawicielem</h3>
              <p>
                Wybierz dogodny dla siebie termin i sposób kontaktu w kalendarzu <span
                className="d-none d-lg-inline">obok</span> <span className="d-lg-none d-inline">poniżej</span>.
              </p>
            </div>
            <div className="col-12 col-lg-8 calendar-widget">
              <InlineWidget url={sponsorDetails.sponsor.calendar_url}
                            styles={{height: '500px'}}
                            pageSettings={{
                              backgroundColor: 'ffffff',
                              hideEventTypeDetails: false,
                              hideLandingPageDetails: false,
                              primaryColor: '00a2ff',
                              textColor: '4d5055'
                            }}/>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Calendar;
