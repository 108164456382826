import React from 'react';
import formatDate from "../utils/formatDate";
import dayjs from 'dayjs';

const DateDuration = ({workshop}) => {
  let day = formatDate(workshop.start_time, 'D')
  if (!dayjs(workshop.start_time).isSame(workshop.finish_time, 'day')) {
    day += ' - ' + formatDate(workshop.finish_time, 'D');
  }

  return (
    <p className="workshop--duration text-center text-lg-left">
      {day} {formatDate(workshop.start_time, 'MMMM YYYY, HH:mm')}
    </p>
  )
}

export default DateDuration;
