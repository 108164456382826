import React, { Fragment, useContext } from 'react';
import { isEmpty, values, first, last } from 'lodash';
import { observer } from "mobx-react-lite";
import clsx from 'clsx';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import formatDate from "../utils/formatDate";

const WorkshopDays = observer(({agendaStore}) => {
  const workshops = agendaStore.rootStore.workshopsStore.workshops;

  const durationDates = () => {
    if (isEmpty(workshops)) return null;

    const fullWorkshops = values(workshops)
    let durationDate = formatDate(first(fullWorkshops).start_time, 'D MMMM YYYY');

    if (fullWorkshops.length > 1) {
      const [firstWorkshopDate, lastWorkshopDate] = [dayjs(first(fullWorkshops)?.start_time), dayjs(last(fullWorkshops)?.start_time)];
      let days = '';

      if (dayjs(firstWorkshopDate).isSame(lastWorkshopDate, 'month')) {
        days = `${firstWorkshopDate.date()} - `
      } else {
        days = formatDate(firstWorkshopDate, 'D MMMM') + ' - '
      }
      durationDate = days + formatDate(lastWorkshopDate, 'D MMMM YYYY');
    }

    return durationDate;
  }

  const workshopsMenuItem = () => {
    return (
      <li className={clsx('congress-days--item workshop-menu-item mr-4 pb-lg-2', agendaStore.congressDay === 'workshop' && 'active')}
          onClick={() => agendaStore.setCongressDay('workshop')}>
        <strong className="d-block">Warsztaty</strong>
        <span
          className="congress-days--item--date">{durationDates()}</span>
      </li>
    );
  }

  return (
    <Fragment>
      {workshopsMenuItem()}
    </Fragment>
  );
})

export default WorkshopDays;
