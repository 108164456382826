import React, { Fragment, useState, useContext, useRef, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CongressContext from '../CongressContext';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import Header from '../shared/Header';
import sliceArray from '../utils/sliceArray'
import Ads from '../shared/Ads';
import useAppointmentGroupsEffect from '../shared/useAppointmentGroupsEffect';
import useSpeakersEffect from '../shared/useSpeakersEffect';
import useVideoBackgroundEffect from '../shared/useVideoBackgroundEffect';
import '../../../stylesheets/current_appointment.scss';
import isMobile from "../utils/isMobile";
import { observer } from 'mobx-react-lite';
import { useAppointmentsStore } from '../rootStoreProvider';
import formatDate from '../utils/formatDate';

dayjs.extend(isToday);

const Appointment = observer(() => {
  useVideoBackgroundEffect('/video/background-hub.jpg');

  const {sections, congressSlug, congress} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const section = sections[sectionSlug];
  const appointmentGroups = useAppointmentGroupsEffect(congressSlug, sectionSlug);
  const speakers = useSpeakersEffect(congressSlug, sectionSlug);
  const store = useAppointmentsStore();
  const currentDay = store.currentDay;
  const appointment = store.currentAppointment;
  const [height, setHeight] = useState(200)
  const agendaEl = useRef(null)

  useEffect(() => {
    setHeight(agendaEl?.current?.clientHeight)
  })

  const lecturer_qa_url = section.details?.lecturer_qa_url;
  const currentGroup = () => {
    if (isEmpty(appointmentGroups)) return null;
    if (isEmpty(appointmentGroups[appointment?.congress_day_id])) return null;

    const group = appointmentGroups[appointment.congress_day_id][appointment.appointment_group_id];
    if (isEmpty(group)) return null

    return [group.title, group.subtitle].join(' ')
  }

  const heightOfAppointmentsView = () => {
    const limit = isMobile() ? 320 : 660;

    return (height >= limit ? limit : height+20)+'px';
  }

  const currentAppointments = () => {
    if (isEmpty(speakers)) return null;

    return appointmentsAgenda();
  }

  const speaker = (appointment_id, subtitle = false) => {
    if (isEmpty(speakers)) return null;
    if (isEmpty(speakers[appointment_id])) return null;
    if (subtitle && speakers[appointment_id].length > 1) return null;

    return speakers[appointment_id].map((speaker, index) =>
      <Fragment key={index}>
        <span
          className="agenda--appointment--speaker pt-2 d-block">{speaker.title} {speaker.first_name} {speaker.last_name}</span>
      </Fragment>
    );
  }

  const appointmentsAgenda = () => (
    <Fragment key={appointment.id}>
      {sliceArray(section.appointments[currentDay.id], appointment).map((appointmentEntry) => (
        <dl key={appointmentEntry.id}
            className={clsx('agenda--appointment past py-2 py-lg-3 mb-0 d-flex flex-row', appointment.id === appointmentEntry.id && 'active')}>
          <dt className="mr-3 pl-2">{formatDate([currentDay?.event_date, appointmentEntry.start_time].join('T'), 'HH:mm')}</dt>
          <dd className="p-0 m-0">
            {appointmentEntry.title}
            {speaker(appointmentEntry.id)}
          </dd>
        </dl>)
      )}
    </Fragment>)

  const handleOngoingAppointments = () => {
    const index = currentAppointmentIndex();

    return <Fragment>
      {(index > 0 && !isEmpty(section.appointments[currentDay.id][index - 1])) ?
        <dl className="agenda--appointment past pb-2 pb-lg-3 d-flex flex-md-row flex-column">
          <dt className="mr-3">{section.appointments[currentDay.id][index - 1].start_time}</dt>
          <dd>
            {section.appointments[currentDay.id][index - 1].title}
            {speaker(section.appointments[currentDay.id][index - 1].id)}
          </dd>
        </dl>
        : null}

      <dl id={`apointment-${index}`}
          className="agenda--appointment active pt-3 pb-2 pb-lg-3 d-flex flex-md-row flex-column">
        <dt className="mr-3">{appointment.start_time}</dt>
        <dd>
          {appointment.title}
          {speaker(appointment.id)}
        </dd>
      </dl>
      {(!isEmpty(section.appointments[currentDay.id][index + 1])) ?
        <dl className="agenda--appointment last pb-2 pb-lg-3 d-flex flex-md-row flex-column">
          <dt className="mr-3">{section.appointments[currentDay.id][index + 1].start_time}</dt>
          <dd>
            {section.appointments[currentDay.id][index + 1].title}
            {speaker(section.appointments[currentDay.id][index + 1].id)}
          </dd>
        </dl>
        : null}
    </Fragment>
  }

  const noAppointments = () => (<Fragment>
      <Header congressSlug={congressSlug} bgColor={isMobile() ? 'dark' : 'light'}/>
      <Ads desktopId={94535} mobileId={94539} className="wide-ad d-block my-3"/>
      <div className="container text-center bg-white text-black">
        <h6 className="h1 my-5 py-5">Wydarzenie nie istnieje</h6>
      </div>
      <Ads mobileId={94540} desktopId={94536} className="wide-ad mx-auto text-center m-3 m-lg-0"/>
      <div/>
    </Fragment>
  );

  if (!dayjs(currentDay?.event_date).isToday()) return noAppointments();

  const isChatPresent = !!section.details.chat_embed;

  return (
    <Fragment>
      <Header congressSlug={congressSlug}/>
        <Ads desktopId={94535} mobileId={94539} className="wide-ad d-block my-3"/>
      <div className="container bg-white">
        <div className="appointment--titles-wrapper row">
          <div
            className={clsx('col-12', lecturer_qa_url ? 'col-lg-8' : 'col-lg-10')}>
            <h5 className="group-title text-titleize pt-2 pt-lg-3">{currentGroup()}</h5>
            <h6 className="main-speaker">{speaker(appointment?.id, true)}</h6>
            <h1 className="h2 appointment--title">{appointment?.title || congress.title}</h1>

          </div>
          {lecturer_qa_url &&
            <div
              className="col-12 col-lg-4 bg-blue-200 d-flex flex-row flex-lg-column justify-content-between align-items-center py-4 px-3 px-lg-4">
              <h3>Pokój wykładowcy</h3>
              <p className="m-0 mt-lg-2">
                <a href={lecturer_qa_url} target="_blank"
                   className="text-nowrap px-3 px-lg-5 py-lg-3 btn btn-grey-outline" data-kongres={congressSlug}>Zadaj
                  pytanie</a>
              </p>
            </div>}
        </div>
        <div
          className="appointment--embed-codes-wrapper z-index-1">
          <div className={clsx("row px-0")}>
            <div className={clsx("px-0 col-12", isChatPresent ? "col-lg-8" : "col-lg-12")}>
              <div dangerouslySetInnerHTML={{__html: section.details.player_embed}}/>
            </div>
            {isChatPresent &&
              <div className="col-12 mt-4 mt-lg-0 col-lg-4 px-0">
                <div dangerouslySetInnerHTML={{__html: section.details.chat_embed}}/>
              </div>
            }
          </div>
        </div>

        <div className="container px-0 mx-auto">
          <HorizontalSponsors/>
        </div>
        <div className="row promo-wrapper shadow-inset py-3">
          <Ads mobileId={94540} desktopId={94536} className="wide-ad mx-auto text-center"/>
        </div>
      </div>
      <div className="container bg-white">
        <div className="row agenda">
          <div className="col-12 col-lg-8 pt-3 pt-lg-5">
            <h3 className="mb-3">Program</h3>
            <h4 className="mb-3">{currentGroup()}</h4>
            <Scrollbars style={{width: '100%', height: heightOfAppointmentsView()}}>
              <div className="agenda--items-wrapper mb-3" ref={agendaEl}>
                {currentAppointments()}
              </div>
            </Scrollbars>
          </div>
          <div className="col-12 col-lg-4 bg-white pt-lg-5">
            <div className="agenda--sponsors bg-blue-200 p-3 py-md-4 px-md-3 mb-4 text-center">
              <Link className="navigation-link active h4"
                    to={`/${congressSlug}/${sectionSlug}/sponsors`}>Wystawcy</Link>
              <br/>
              <Link className="text-nowrap px-3 px-lg-5 py-lg-3 btn btn-grey-outline mt-3"
                    to={`/${congressSlug}/${sectionSlug}/sponsors`}
                    data-kongres={congressSlug}>Odwiedź
                stoiska</Link>
            </div>
            <Ads className="mb-3" mobileId={94542} desktopId={94537}/>
          </div>
        </div>
      </div>
    </Fragment>
  );
})

export default Appointment;
