import React from 'react';
import clsx from 'clsx';

const Sponsors = ({sponsors, titleOn, className}) => {
  const buildSponsors = () => (
    sponsors.map(sponsor => (
      <li className="sponsor-logo--wrapper pr-2" key={sponsor.id}>
        <img className="sponsor-logo--item" src={sponsor?.avatar} alt={sponsor?.name}/>
      </li>))
  );
  if (!className) className = 'justify-content-center'

  return (
    <ul className={clsx('workshop mb-0 d-flex flex-row flex-wrap list-unstyled align-items-center', className)}>
      {titleOn && <li className="sponsor-logo--title text-center text-lg-left pr-3">Sponsorzy</li>}
      {buildSponsors()}
    </ul>
  );
}

export default Sponsors;
