import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import CongressContext from '../CongressContext';
import { isEmpty, findIndex } from 'lodash';
import clsx from 'clsx';
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion';
import Header from '../shared/Header';
import ContactForm from './ContactForm';
import Calendar from './Calendar';
import Attachments from './Attachments';
import Products from './Products';
import Links from './Links';
import Ads from '../shared/Ads';
import Video from './Video';
import Player from './Player';
import useKeyboardShortcut from '../shared/useKeyboardShortcut';
import '../../../stylesheets/sponsor.scss'

const SponsorDesktop = ({sponsorDetails, styles, textStyles}) => {
  const {user, congressSlug, csrf} = useContext(CongressContext);
  const {sectionSlug, sponsorSlug} = useParams();
  const [currentSection, setCurrentSection] = useState('start');
  const siteSections = [{id: 'start', name: 'Start'}]
  const buildSiteSections = () => {
    if (sponsorDetails.sponsor.video_embed) siteSections.push({id: 'video', name: 'Wideo'});
    if (!isEmpty(sponsorDetails.attachments)) siteSections.push({id: 'attachments', name: 'Materiały do pobrania'});
    if (!isEmpty(sponsorDetails.products)) siteSections.push({id: 'products', name: 'Produkty'});
    if (!isEmpty(sponsorDetails.links)) siteSections.push({id: 'links', name: 'Polecane strony'});
    if (!isEmpty(sponsorDetails.sponsor.calendar_url)) siteSections.push({id: 'calendar', name: 'Kalendarz spotkań'});
    if (!isEmpty(sponsorDetails.sponsor.contact_emails)) siteSections.push({
      id: 'contact',
      name: 'Formularz kontaktowy'
    });
  }
  buildSiteSections();

  const showMenu = () => {
    if (!showArrows()) return null;

    const availableSections = []
    siteSections.forEach(section => (
      availableSections.push(<li key={section['id']}
                                 className={clsx('active rounded-pill py-1 px-4 text-white mx-1', currentSection === section['id'] && ('bg-dark-blue-600'))}
                                 onClick={() => setCurrentSection(section['id'])}>{section['name']}</li>)
    ))

    return (
      <div className="container text-center mt-3">
        <ul
          className="sponsor--navigation list-unstyled rounded-pill bg-dark-blue-500 p-2 d-inline-flex flex-row align-items-center justify-content-center">
          {availableSections}
        </ul>
      </div>)
  }

  const currentSectionIndex = () => findIndex(siteSections, section => section.id === currentSection);
  const nextSection = () => setCurrentSection(siteSections[currentSectionIndex() + 1]?.id || siteSections[0].id)
  const prevSection = () => setCurrentSection(siteSections[currentSectionIndex() - 1]?.id || siteSections[siteSections.length - 1].id)

  useKeyboardShortcut(['arrowright'], () => nextSection())
  useKeyboardShortcut(['arrowleft'], () => prevSection())

  const showArrows = () => {
    return siteSections.length > 1
  }

  return (
    <AnimateSharedLayout>
      {styles}
      <div className="sponsor d-flex flex-column flex-grow-1">
        <div className="sponsor--container">
          <Header congressSlug={congressSlug}/>
          <div className="container d-flex flex-row align-items-center justify-content-center mt-3">
            {(showArrows() && <div className="sponsor--left-arrow" onClick={() => prevSection()}>
                <i className="fas fa-2x fa-angle-left text-white"/>
              </div>
            )}
            <section className="sponsor--view mx-2 px-3">
              <AnimatePresence>
                {currentSection === 'start' &&
                <Player sponsorDetails={sponsorDetails} textStyles={textStyles} congressSlug={congressSlug}/>}

                {currentSection === 'video' &&
                <Video sponsorDetails={sponsorDetails} textStyles={textStyles} congressSlug={congressSlug}/>}

                {currentSection === 'attachments' &&
                <Attachments attachments={sponsorDetails.attachments} sponsor={sponsorDetails.sponsor}/>}

                {currentSection === 'products' &&
                <Products products={sponsorDetails.products}/>}

                {currentSection === 'links' && <Links
                  links={sponsorDetails.links}
                  sponsor={sponsorDetails.sponsor}
                  congressSlug={congressSlug}
                  sectionSlug={sectionSlug}
                />}

                {currentSection === 'calendar' && <Calendar sponsorDetails={sponsorDetails} textStyles={textStyles}/>}

                {currentSection === 'contact' && <motion.div
                  layout
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}>
                  <div className="text-white p-4" id="contact-form">
                    <ContactForm
                      sponsorName={sponsorDetails.sponsor.name}
                      policyUrl={sponsorDetails.sponsor.policy_url}
                      postUrl={`/${congressSlug}/${sectionSlug}/sponsor/${sponsorSlug}/message`}
                      user={user}
                      csrf={csrf}
                      congressSlug={congressSlug}
                    />
                  </div>
                </motion.div>}
              </AnimatePresence>
            </section>
            {(showArrows() && <div className="sponsor--right-arrow" onClick={() => nextSection()}>
                <i className="fas fa-2x fa-angle-right text-white"/>
              </div>
            )}
          </div>
          {showMenu()}
          <div className="container text-center">
            <Ads desktopId={97728} className="my-3"/>
          </div>
        </div>
      </div>
    </AnimateSharedLayout>
  );
}

export default SponsorDesktop;
