import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { keys, isEmpty } from 'lodash';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import CongressContext from '../CongressContext';
import { useAppointmentsStore } from "../rootStoreProvider";

const Sections = observer(({className, urlSuffix, showWorkshops}) => {
  const {sections, congressSlug, workshops} = useContext(CongressContext);
  const store = useAppointmentsStore();

  return (<ul className="sections list-unstyled">
    {keys(sections).map((slug) =>
      <li className={clsx('sections--item', store.currentCongressTab === slug && 'active')} key={slug}>
        <Link className={clsx(className, store.currentCongressTab === slug && 'active')}
              to={`/${congressSlug}/${slug}/${urlSuffix}`} onClick={() => store.setCurrentCongressTab(slug)}
              data-kongres={congressSlug}>{sections[slug].details.title}</Link>
      </li>)}
    {(!isEmpty(workshops) && showWorkshops) &&
      <li className={clsx('sections--item', store.currentCongressTab === 'workshops' && 'active')}>
        <Link className={clsx(className, store.currentCongressTab === 'workshops' && 'active')}
              onClick={() => store.setCurrentCongressTab('workshops')}
              to="#"
              data-kongres={congressSlug}>Warsztaty</Link>
      </li>}
  </ul>);
});

Sections.defaultProps = {
  className: 'sections--link',
  urlSuffix: '',
  showWorkshops: true
}

Sections.propTypes = {
  className: PropTypes.string,
  urlSuffix: PropTypes.string
}

export default Sections;
