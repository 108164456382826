import React from 'react';
import ContentLoader from 'react-content-loader';

const WatchNowPreloader = ({height, width, ...props}) => (
  <ContentLoader
    speed={1}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="21" rx="5" ry="5" width="260" height="12" />
    <rect x="0" y="50" rx="5" ry="5" width="220" height="12" />
    <rect x="0" y="82" rx="5" ry="5" width="80" height="10" />
    <rect x="120" y="82" rx="5" ry="5" width="80" height="10" />
    <rect x="0" y="108" rx="5" ry="5" width="130" height="30" />
  </ContentLoader>
);

WatchNowPreloader.defaultProps = {
  width: 300,
  height: 150
}

export default WatchNowPreloader;
