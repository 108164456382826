import { useState, useEffect } from 'react';
import apiFetch from '../utils/apiFetch';

const useSpeakersEffect = (congressSlug, sectionSlug) => {
  const [hasSpeakersError, setSpeakersError] = useState(false);
  const [speakers, setSpeakers] = useState({});
  
  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/appointments_speakers`, setSpeakersError, setSpeakers);
  }, [sectionSlug]);

  return speakers;
}

export default useSpeakersEffect;
