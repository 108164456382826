import React, { Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, values } from 'lodash';
import CongressContext from '../CongressContext';
import useSpeakersEffect from '../shared/useSpeakersEffect';
import useAppointmentGroupsEffect from '../shared/useAppointmentGroupsEffect';
import { observer } from 'mobx-react-lite';
import formatDate from '../utils/formatDate';

const Appointments = observer(({congressDay}) => {
  const {sections, congressSlug} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const section = sections[sectionSlug];
  const speakers = useSpeakersEffect(congressSlug, sectionSlug);
  const appointmentGroups = useAppointmentGroupsEffect(congressSlug, sectionSlug);

  const appointmentGroupList = () => {
    if (isEmpty(appointmentGroups) || isEmpty(congressDay)) return null;

    return values(appointmentGroups[congressDay.id]).map(group =>
      <dl className="appointments--appointment-groups d-flex flex-md-row flex-column" key={group.id}>
        <dt className="mr-4 text-nowrap">{group.title}</dt>
        <dd><a href={`#entry_${group.id}`} className="appointments--appointment-groups--link">{group.subtitle}</a></dd>
      </dl>
    )
  };

  const appointmentGroupEntry = id => {
    if (isEmpty(appointmentGroups)) return null;
    if (isEmpty(appointmentGroups[congressDay?.id])) return null;

    return appointmentGroups[congressDay.id][id];
  }

  const speaker = (appointment) => {
    if (isEmpty(speakers)) return null;
    if (isEmpty(speakers[appointment.id])) return null;

    return <div
      className="appointments--agenda--appointment--speaker d-flex flex-row align-items-start">
      <ul className="list-unstyled mb-0">
        {speakers[appointment.id].map((speaker, index) => (
          <li key={index}>{speaker.title} {speaker.first_name} {speaker.last_name}</li>))}
      </ul>
      <div className="appointments--agenda--badge">{appointment.badge}</div>
    </div>;
  }

  const appointmentList = () => {
    if (isEmpty(congressDay) || isEmpty(section.appointments[congressDay?.id])) return null;

    let title = null;
    let oldSectionId = null;

    return section.appointments[congressDay.id].map(appointment => {
      if (!isEmpty(appointmentGroupEntry(appointment.appointment_group_id))) {
        let entry = appointmentGroupEntry(appointment.appointment_group_id);
        title = <h4 className="h5 pb-3 mb-3" id={`entry_${entry.id}`}>{entry.title} - {entry.subtitle}</h4>
      }
      if (oldSectionId === appointment.appointment_group_id) {
        title = null
      }
      oldSectionId = appointment.appointment_group_id;

      return <Fragment key={appointment.id}>
        {title}
        <dl className="appointments--agenda--appointment px-3 px-md-4 d-flex flex-row">
          <dt className="mr-3">{formatDate([congressDay?.event_date, `${appointment.start_time}`].join('T'), 'HH:mm')}</dt>
          <dd>
            {appointment.title}
            {speaker(appointment)}
          </dd>
        </dl>
      </Fragment>
    })
  }

  return (
    <Fragment>
      {appointmentGroupList()}
      {appointmentList()}
    </Fragment>
  );
})

export default Appointments;
