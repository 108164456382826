export default [
  //TODO hack for Accordion first group check how to solve this
  {
    question: '',
    answer: ''
  },
  {
    question: 'Dlaczego zegar odliczający czas dobił do zera, a nie wyświetliła się transmisja na żywo?',
    answer: 'Jeśli nadal nie wyświetliła się transmisja, oznacza to, że nie rozpoczęliśmy jeszcze przekazu. Pamiętaj, że zanim strumień wideo pojawi się w obszarze podglądu, może upłynąć nawet 10 sekund od rozpoczęcia transmisji na żywo i jest to naturalne zjawisko.'
  },
  {
    question: 'Dlaczego po uruchomieniu transmisji na żywo obraz się zacina i nie działa płynnie? ',
    answer: `
  <h6 class="mb-2">Najczęstsze przyczyny: </h6>
<ul>
<li>Prędkość łącza internetowego odbiorcy jest niewystarczająca (pobieranie min. 2,5 Mbit/s i 3,5 Mbit/s dla jakości HD).  </li>
<li>Oprogramowanie blokujące reklamy lub antywirusowe może wpływać na płynność odtwarzania transmisji.  </li>
<li>Urządzenie odbiorcy ma niewystarczające parametry techniczne (przestarzały lub nieaktualizowany system, słaby procesor lub karta graficzna).  </li>
<li>Nadawca sygnału ma problemy techniczne z komputerem lub łączem</li>
</ul>
<h6 claas="mb-2">Jak mogę naprawić problem:  </h6>
<ul>
<li>Upewnij się, że prędkość łącza jest wystarczająca (poniżej podajemy jak to zrobić), a jeżeli korzystasz z WiFi, czy siła sygnału jest odpowiednio wysoka.  </li>
<li>Spróbuj uruchomić transmisję w innych przeglądarkach, np. Chrome, Firefox, Opera, Safari, Microsoft Edge.  </li>
<li>W przypadku wszystkich urządzeń (zarówno komputera, jak i telefonu czy tabletu) warto pozamykać wszystkie inne aplikacje działające w tle oraz otwarte karty przeglądarki.  </li>
<li>Jeżeli jest taka możliwość, w pasku odtwarzacza, w którym widoczna jest transmisja webinarium, możesz obniżyć jakość obrazu (domyślnie jest to Full HD). Spowoduje to odciążenie łącza i powinno poprawić jakość transmisji.  </li>
<li>Jeżeli korzystasz z internetu dostarczanego przez sieć komórkową, w miarę możliwości – przełącz urządzenie do lokalnej sieci (np. WiFi, przewód, itp.).  </li>
</ul>`
  },
  {
    question: 'Skąd mam wiedzieć, że przyczyną złej jakości transmisji lub odtwarzania nagranego materiału jest moje łącze internetowe? ',
    answer: `
<ul>
<li>Spróbuj wykonać test łącza np. narzędziem dostępnym na stronie: #{link_to 'speedtest.net/pl', 'https://www.speedtest.net/pl', target: '_blank'}. Do oglądania webinarium minimalne parametry to: pobieranie 2,5 Mbit/s i 3,5 Mbit/s dla jakości HD.  </li>
<li>Spróbuj też skorzystać z innej przeglądarki. Rekomendujemy korzystanie z przeglądarek Chrome, Firefox, Safari oraz Microsoft Edge. Zalecane jest używanie aktualnej wersji przeglądarki.  </li>
<li>Sprawdź, czy przyczyną nie jest oprogramowanie antywirusowe, którego wewnętrzne ustawienia mogą spowodować niską jakość transmisji lub jej przerwanie.  </li>
<li>Przyczyną mogą być też niewystarczające parametry techniczne Twojego urządzenia (przestarzały lub nieaktualizowany system, słaby procesor lub karta graficzna). Dlatego należy pozamykać wszystkie programy/aplikacje działające w tle oraz okna i karty przeglądarki, z których w danym momencie nie korzystasz.  </li>
</ul>`
  },
  {
    question: 'Co zrobić, gdy dźwięk nie jest zsynchronizowany z obrazem?',
    answer: 'Proszę odświeżyć (przeładować) stronę (np. używając skrótu Ctrl&nbsp;+&nbsp;F5 lub Cmd&nbsp;+&nbspR na komputerze apple).'
  },
  {
    question: 'W jakich przeglądarkach najlepiej oglądać transmisję? ',
    answer: `
Transmisja obsługiwana jest przez wszystkie popularne przeglądarki: Chrome, Firefox, Safari, Microsoft Edge. Zalecane jest używanie aktualnej wersji przeglądarki.
<br>
<br>
Zdecydowana większość naszych webinariów oraz nagrań wyświetla się poprawnie w przeglądarce Microsoft Internet Explorer. Jednak z uwagi na fakt, że ta przeglądarka nie jest już aktualizowana (producent zastąpił ją nową: Microsoft Edge), nie możemy zagwarantować poprawnego wyświetlania się wszystkich materiałów przy jej użyciu.`
  }
];
