import { findIndex } from 'lodash';

const sliceArray = (arr, currentAppointment) => {
  const arrLength = arr.length;
  const arrLimit = 8;
  const currentIndex = findIndex(arr, entry => entry?.id === currentAppointment?.id) || 0

  if (currentIndex === 0) return arr.slice(0, arrLimit);

  if (arrLength - arrLimit < currentIndex) return arr.slice(arrLength - arrLimit)

  return arr.slice(currentIndex - 1, currentIndex - 1 + arrLimit);
}

export default sliceArray;

