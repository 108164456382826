import React, { useContext, Fragment } from 'react';
import { isEmpty, filter } from 'lodash';
import clsx from 'clsx';
import CongressContext from '../CongressContext';
import { Link, useParams, useHistory } from 'react-router-dom';

const Rank = ({sponsors, rankId, title}) => {
  if (isEmpty(sponsors)) return null;

  const history = useHistory();
  const rankColors = ['bg-teal-500', 'bg-gold-500', 'bg-gray-200', 'bg-blue-400'];
  const {congressSlug} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const filteredSponsors = filter(sponsors, (sponsor) => sponsor.rank === rankId);
  if (isEmpty(filteredSponsors)) return null;

  const urlPrefix = `/${congressSlug}/${sectionSlug}/sponsors/`;
  const sponsorContainer = sponsor => {
    if (!sponsor.has_room) return null;

    return (<div className="sponsors--sponsor-card mt-3 mb-lg-5 mb-3 mx-4 d-flex flex-column" key={sponsor.id}
                 onClick={() => history.push(`${urlPrefix}${sponsor.slug}`)}>
      <div className={clsx("card h-100 flex-grow-1 position-relative rounded-0 border-0")}>
        <div className={clsx('rank-label', rankColors[rankId])}>
          <strong className={clsx('mx-auto px-3 py-0 text-white', rankColors[rankId])}>{title}</strong>
        </div>
        <div
          className="card-body d-flex flex-column align-content-center align-items-start justify-content-between">
          <div className="title-wrapper mb-4 d-flex flex-row justify-content-start align-items-center">
            <div className="sponsors--logo-wrapper mr-3">
              <img src={sponsor.avatar} alt={sponsor.name} className="sponsors--logo"/>
            </div>
            <h3 className="h4 card-title m-0 p-0">{sponsor.name}</h3>
          </div>
          {sponsor.headline && <p className="card-text text-left flex-grow-1">{sponsor.headline}</p>}
          <ul className="list-unstyled badges d-flex flex-row flex-wrap">
            {sponsor.contact_emails && (
              <li className="bg-gray-100 rounded-pill px-3 text-nowrap mr-2 mb-2"><small>formularz kontaktowy</small>
              </li>)}
            {sponsor.player_embed && (
              <li className="bg-gray-100 rounded-pill px-3 text-nowrap mr-2 mb-2"><small>wideo</small></li>)}
            {sponsor.products && (
              <li className="bg-gray-100 rounded-pill px-3 text-nowrap mr-2 mb-2"><small>produkty</small></li>)}
            {sponsor.attachments && (
              <li className="bg-gray-100 rounded-pill px-3 text-nowrap mr-2 mb-2"><small>materiały do pobrania</small>
              </li>)}
            {sponsor.links && (
              <li className="bg-gray-100 rounded-pill px-3 text-nowrap mr-2 mb-2"><small>polecane strony</small></li>)}
            {sponsor.calendar_url && (
              <li className="bg-gray-100 rounded-pill px-3 text-nowrap mb-2"><small>kalendarz spotkań</small></li>)}
          </ul>
          <Link to={`${urlPrefix}${sponsor.slug}`}
                className="btn btn-grey-outline"
                target='_blank'
                onClick={e => e.stopPropagation()}
                data-kongres={congressSlug}>
            Odwiedź stoisko</Link>
        </div>
      </div>
    </div>)
  };

  return (
    <Fragment>
      {filteredSponsors.map((sponsor, index) => sponsorContainer(sponsor, index))}
    </Fragment>
  );
}

export default Rank;
