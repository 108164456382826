import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty, values } from 'lodash';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import useVideoBackgroundEffect from '../shared/useVideoBackgroundEffect';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import Sponsors from './Sponsors';
import DateDuration from "./DateDuration";

const Workshops = () => {
  useVideoBackgroundEffect();
  const {sectionSlug} = useParams();
  const {congressSlug, workshops} = useContext(CongressContext);


  const buildEntries = () => {
    if (isEmpty(workshops)) return null;

    const workshopButton = (workshop) => {
      return workshop.has_access ?
        <Link to={`/${congressSlug}/${sectionSlug}/workshops/${workshop.slug}`}
              className="btn btn-grey-outline d-block d-inline text-nowrap">Weź udział</Link>
        :
        <a className="btn btn-disabled disabled d-block d-inline text-nowrap">Weź udział</a>
    }

    return values(workshops).map(workshop => (
      <div key={workshop.id} className="light-bg mb-3 p-4">
        <h4 className="text-center text-lg-left">{workshop.title}</h4>
        <DateDuration workshop={workshop}/>
        <div className="d-flex flex-lg-row flex-column justify-content-between">
          <div className="mb-4 nowrap">
            {workshopButton(workshop)}
          </div>
          <Sponsors sponsors={workshop.sponsors} titleOn={true}/>
        </div>
      </div>
    ))
  };

  return (
    <>
      <Header congressSlug={congressSlug} bgColor="light"/>
      <div className="contests">
        <h2 className="text-center light-text my-3 my-md-5">Warsztaty</h2>
        <div className="container">
          {buildEntries()}
        </div>
      </div>
      <HorizontalSponsors/>
    </>
  );
}

export default Workshops;
