import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import CongressContext from '../CongressContext';
import { isEmpty } from 'lodash';
import apiFetch from "../utils/apiFetch";
import useVideoBackgroundEffect from '../shared/useVideoBackgroundEffect';
import ahoy from 'ahoy.js';
import getContrastColor from '../utils/getContrastColor';
import isMobile from '../utils/isMobile';
import SponsorDesktop from './SponsorDesktop';
import SponsorMobile from './SponsorMobile';
import '../../../stylesheets/sponsor.scss'

const Sponsor = () => {
  useVideoBackgroundEffect();
  const {congressSlug} = useContext(CongressContext);
  const {sectionSlug, sponsorSlug} = useParams();
  const [hasError, setError] = useState(false);
  const [sponsorDetails, setSponsorDetails] = useState({});

  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/sponsor/${sponsorSlug}`, setError, setSponsorDetails);
  }, []);

  if (isEmpty(sponsorDetails)) return null;

  const styles = () => {
    const backgroundImage = !isEmpty(sponsorDetails.sponsor.background_image) ? `url(${sponsorDetails.sponsor.background_image})` : 'none';
    const backgroundColor = sponsorDetails.sponsor.background_color || 'transparent';

    return (<style dangerouslySetInnerHTML={{
        __html: `
      .sponsor::before {
        background-image: ${backgroundImage};
        background-color: ${backgroundColor};
      }`
      }}/>
    )
  }

  const textStyles = {
    color: getContrastColor(sponsorDetails.sponsor.background_color)
  }

  ahoy.track('sponsor_show', {
    sponsor_id: sponsorDetails.sponsor.id,
    section_slug: sectionSlug,
    congress_slug: congressSlug
  });

  return isMobile() ?
    <SponsorMobile sponsorDetails={sponsorDetails} styles={styles()} textStyles={textStyles} />
    :
    <SponsorDesktop sponsorDetails={sponsorDetails} styles={styles()} textStyles={textStyles} />
}

export default Sponsor;
