import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { motion } from 'framer-motion';
import clsx from 'clsx';

const Player = ({sponsorDetails, textStyles}) => {
  const wordCount = str => {
    var m = str.match(/[^\s]+/g)
    return m ? m.length : 0;
  }

  const showArrows = () => wordCount(sponsorDetails.sponsor.description) >= 20;

  return (
    <motion.div
      layout
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className="container light-text">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div
              className="title-wrapper mb-4 d-flex flex-column text-center text-lg-left">
              <div className="sponsor--logo-wrapper mr-lg-4 mb-lg-4 align-self-lg-start">
                <img src={sponsorDetails.sponsor.avatar} alt={sponsorDetails.sponsor.name}
                     className="sponsor--logo-img mx-auto mx-lg-0"/>
              </div>
              <div className="body mr-lg-4 text-center text-lg-left">
                <h3 className="card-title m-0 p-0 mb-4" style={textStyles}>
                  <span className="d-none d-lg-block">Witamy na stoisku</span>
                  <span className="d-block mt-4">{sponsorDetails.sponsor.name}</span></h3>
                <div className={clsx(showArrows() && 'sponsor--description-wrapper')}>
                <Scrollbars style={{width: '100%', height: '15rem'}}>
                  <p style={textStyles}
                     className="sponsor--description text-left">{sponsorDetails.sponsor.description}</p>
                </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div dangerouslySetInnerHTML={{__html: sponsorDetails.sponsor.player_embed}}/>
          </div>
        </div>
      </div>
    </motion.div>);
}

export default Player;
