import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import dots from '../../../images/icons/dots.svg';
import arrowLeft from '../../../images/icons/arrow-left.svg';
import WatchNowEvents from './WatchNowEvents';

const MobileMenu = ({congressSlug,
                      hasContests,
                      hasWorkshops,
                      hasCurrentWorkshops,
                      hasCurrentAppointments,
                      currentAppointment,
                      watchNow,
                      currentWatchNowTab,
                      setCurrentWatchNowTab,
                      hasProducts,
                    }) => {
  const {sectionSlug} = useParams();
  const [showWatchNowMenu, setShowWatchNowMenu] = useState(false);
  const urlPrefix = `/${congressSlug}/${sectionSlug}`;
  const navExpand = [].slice.call(document.querySelectorAll('.nav-expand'))

  navExpand.forEach(item => {
    if (item.querySelector('.nav-link-block')) {
      item.querySelector('.nav-link-block').addEventListener('click', () => item.classList.add('active'))
    }
    if (item.querySelector('.nav-back-link')) {
      item.querySelector('.nav-back-link').addEventListener('click', () => item.classList.remove('active'))
    }
  })

  const [activeNavigation, setActiveNavigation] = useState(false);

  const watchNowMobileMenu = () => {
    return (
      <ul className="nav-items nav-expand-content">
        <li className="nav-item-back d-flex justify-content-between align-items-center">
          <Link className="nav-link-block nav-back-link" to="#">
            <img src={arrowLeft} alt="back"/>
          </Link>
          <div className={'text-uppercase text-nowrap small'}>
            <img src={dots} alt="." className="mr-2 pb-1"/>
            na żywo
          </div>
        </li>
        <li className="nav-item-horizontal">
          <ul className="list-unstyled d-flex justify-content-between px-4 mt-2">
            <li onClick={() => setCurrentWatchNowTab('appointments')}
                className={clsx("flex-fill pb-2",
                  currentWatchNowTab === 'appointments' && 'active',
                  !hasCurrentAppointments && 'd-none')}
            >Sesje</li>
            <li onClick={() => setCurrentWatchNowTab('workshops')}
                className={clsx("flex-fill pl-3 pb-2",
                  currentWatchNowTab === 'workshops' && 'active',
                  !hasCurrentWorkshops && 'd-none')
                }
            >Warsztaty</li>
          </ul>
        </li>
        <WatchNowEvents className="nav-item event d-flex flex-column justify-content-between"
                        currentWatchNowTab={currentWatchNowTab} congressSlug={congressSlug}
                        sectionSlug={sectionSlug} currentAppointment={currentAppointment} />
      </ul>
    )
  }

  return (
    <Fragment>
      <div className={clsx(activeNavigation && 'active')}>
        <div id="ham" onClick={() => setActiveNavigation(!activeNavigation)}/>
      </div>
      <div className={clsx(activeNavigation && 'nav-is-toggled')}>
        <nav className="nav-drill">
          <ul className="nav-items nav-level-1">
            {watchNow &&
            <li className="nav-item nav-expand nav-item nav-expand-link">
                <Link className="nav-link-block d-block" to="#" onClick={() => setShowWatchNowMenu(!showWatchNowMenu)}>
                  <img src={dots} alt="." className="mr-2 pb-1"/>
                  Oglądaj</Link>
              {activeNavigation && watchNowMobileMenu()}
            </li>}
            <li className="nav-item">
              <Link className="nav-link-block" to={`${urlPrefix}/sponsors`} data-kongress={congressSlug}>Wystawcy</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link-block" to={`${urlPrefix}/agenda`} data-kongress={congressSlug}>Program</Link>
            </li>
            {(hasContests && <li className="nav-item">
                <Link className="nav-link-block" to={`${urlPrefix}/contests`} data-kongress={congressSlug}>Konkursy</Link>
              </li>
            )}
            {(hasWorkshops && <li className="nav-item">
                <Link className="nav-link-block" to={`${urlPrefix}/workshops`} data-kongress={congressSlug}>Warsztaty</Link>
              </li>
            )}
            {(hasProducts && <li className="nav-item">
              <Link className="nav-link-block" to={`${urlPrefix}/marketplace`} data-kongress={congressSlug}>Zakupy</Link>
            </li>
            )}
            <li className="nav-item">
              <Link className="nav-link-block" to={`${urlPrefix}/contact`} data-kongress={congressSlug}>Kontakt</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link-block" rel="nofollow" data-method="delete" href={`/${congressSlug}/sign_out`}>Wyloguj</a>
            </li>
          </ul>
        </nav>
      </div>
    </Fragment>
  )
}


MobileMenu.propTypes = {
  congressSlug: PropTypes.string,
  bgColor: PropTypes.string
};

export default MobileMenu;
