const getContrastColor = (hexcolor) => {
  hexcolor = hexcolor.replace("#", "");
  if (hexcolor === '') return 'inherit';

  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#000000' : '#ffffff';
}

export default getContrastColor;
