import React from 'react'
import { makeObservable, observable, action} from 'mobx'

export class AgendaStore {
  rootStore
  congressDay

  constructor(rootStore) {
    makeObservable(this, {
      congressDay: observable,
      setCongressDay: action
    });
    this.rootStore = rootStore;
  }

  setCongressDay(day) {
    this.congressDay = day;
  }
}

