import { AppointmentsStore } from './AppointmentsStore'
import { WorkshopsStore } from './WorkshopsStore'
import { AgendaStore } from './AgendaStore'

class RootStore {
  appointmentsStore
  workshopsStore
  agendaStore

  constructor() {
    this.appointmentsStore = new AppointmentsStore(this);
    this.workshopsStore = new WorkshopsStore(this);
    this.agendaStore = new AgendaStore(this);
  }
}

export default RootStore;
