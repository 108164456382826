import React, { useState } from 'react';
import { useFormik } from 'formik';
import { apiPost } from '../utils/apiFetch';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Pole wymagane';
  } else if (values.name.length < 3) {
    errors.name = 'Imię jest niepoprawne';
  }

  if (!values.email) {
    errors.email = 'Pole wymagane';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Niepoprawny adres email';
  }

  if (!values.message) {
    errors.message = 'Pole wymagane';
  } else if (values.email.length < 10) {
    errors.message = 'Proszę wpisać minimum 10 znaków';
  }
  return errors;
};

const ContactForm = ({sponsorName, policyUrl, postUrl, user, csrf, congressSlug, textStyles}) => {
  const [hasError, setError] = useState(false);
  const [response, setResponse] = useState({ status: null })
  const full_name = [user.first_name, user.last_name].join(' ') || ''

  const formik = useFormik({
    initialValues: {
      name: full_name,
      message: `Proszę o kontakt,\n\bPozdrawiam ${full_name}`,
      email: user.email,
    },
    validate,
    onSubmit: values => {
      delete values['agreement'];
      apiPost(postUrl, csrf, { congresses_sponsors_message: values }, setError, setResponse);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-row" style={textStyles}>
        <div className="col-12">
          <h3 className="h4 text-center mb-lg-4">Potrzebujesz więcej informacji? Napisz do nas!</h3>
        </div>
        <div className="form-group col-12 col-md-4 offset-md-2">
          <label htmlFor="name">Imię</label>
          <input
            id="name"
            name="name"
            className="form-control"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.errors.name ?
            <div className="invalid">{formik.errors.name}</div> : null}
        </div>
        <div className="form-group col-12 col-md-4">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.errors.email ?
            <div className="invalid">{formik.errors.email}</div> : null}
        </div>

        <div className="form-group col-12 col-md-8 offset-md-2">
          <label htmlFor="message">Wiadomość</label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ?
            <div className="invalid">{formik.errors.message}</div> : null}
        </div>

        <div className="form-group col-12 col-md-8 offset-md-2">
          <div className="form-check">
            <input className="form-check-input" name="agreement" type="checkbox" id="agreement" required
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
            />
            <label className="form-check-label" htmlFor="agreement">
              Wypełnienie formularza jest równoznaczne z wyrażeniem zgody na przetwarzanie Twoich danych przez
              administratora – firmę {sponsorName}, której zostaną przekazane powyższe dane celem nawiązania z Tobą
              kontaktu. W każdej chwili możesz wycofać zgodę na przetwarzanie danych lub zgłosić sprzeciw wobec ich
              przetwarzania w określony sposób poprzez kontakt z administratorem. Informacje o administratorze oraz
              celach i sposobie przetwarzania Twoich danych a także pozostałych uprawnieniach, jakie Ci przysługują
              w związku z przetwarzaniem powyższych informacji, dostępne są na <a href={policyUrl} target="_blank">stronie
              internetowej</a>.
            </label>
            {formik.errors.agreement ?
              <div className="invalid-feedback">{formik.errors.agreement}</div> : null}
          </div>
          <div className="d-flex justify-content-center align-items-center">
          <button className="mt-3 btn btn-grey-outline px-4 mr-4" type="submit" data-kongres={congressSlug}>Wyślij</button>
          {response.status === 'sent' && (<span>wiadomość wysłana</span>)}
          </div>
        </div>
      </div>
    </form>
  )
};
export default ContactForm;
