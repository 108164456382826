import React, { useState, useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { isEmpty, first, keys } from 'lodash';
import clsx from 'clsx';
import CongressContext from '../CongressContext';
import ScriptTag from 'react-script-tag';
import ShowMoreText from 'react-show-more-text';
import Header from '../shared/Header';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import Ads from '../shared/Ads';
import Sponsors from './Sponsors';
import isMobile from '../utils/isMobile';
import '../../../stylesheets/workshops.scss';

const Workshop = () => {
  const {workshopSlug, sectionSlug} = useParams();
  const {workshops, congressSlug} = useContext(CongressContext);
  const workshop = workshops[workshopSlug]
  const [activeSpeaker, setActiveSpeaker] = useState(first(workshop.speakers))

  if (!workshop.has_access) {
    return <Redirect to={`/${congressSlug}/${sectionSlug}/workshops`}/>
  }

  const speakerFullName = (speaker) => <p
    className="ml-md-3 mb-0 speakers--active-speaker">{speaker.title} {speaker.first_name} {speaker.last_name}</p>

  const speakers = () => {
    const speakersItems = workshop.speakers.map(speaker => (
      <li
        className={clsx('d-flex flex-row align-items-center flex-wrap ml-md-3 mb-2 mr-3', activeSpeaker?.id === speaker.id && 'active')}
        key={speaker.id}>
        <div className="speakers--item">
          <img src={speaker.avatar} className="speakers--avatar rounded-circle"/>
        </div>
        {speakerFullName(speaker)}
      </li>
    ))

    return <>
      <h6 className="ml-md-3 mt-lg-0 mt-3 font-weight-bold">Prowadzący</h6>
      <ul className="list-unstyled speakers1 d-flex flex-column">{speakersItems}</ul>
    </>
  }

  const description = workshop => (
    isMobile() ?
      <ShowMoreText
        lines={2}
        more='rozwiń'
        less='zwiń'
      >{workshop.description}</ShowMoreText>
      :
      <p>{workshop.description}</p>
  );

  return (
    <>
      <div className="light-bg align-self-stretch">
        <Header congressSlug={congressSlug} bgColor="light"/>
        <Ads desktopId={94535} mobileId={94539} className="wide-ad d-block my-3"/>
        <div className="container px-lg-0">
          <div className="workshop my-5 p-4">
            <div className="d-md-flex flex-column justify-content-md-center mt-4">
              {
                !isEmpty(workshop.player_embed) && workshop.player_embed.match(/embed\.clickmeeting/) ?
                  <ScriptTag type="text/javascript" src={workshop.player_embed}/>
                  :
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <div dangerouslySetInnerHTML={{__html: workshop.player_embed}}/>
                    </div>
                    <div className="col-12 col-md-4">
                      <div dangerouslySetInnerHTML={{__html: workshop.chat_embed}}/>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
        <Ads className="my-3 wide-ad mx-auto" desktopId={94536} mobileId={94540}/>
        <div className="container">
          <div className="workshop">
            <div className="row">
              <div className="col-12 col-lg-7 pb-lg-0 pb-2 with-border">
                <h6>Warsztaty</h6>
                <h2>{workshop.title}</h2>
                {description(workshop)}
              </div>
              <div className="speakers col-12 col-lg-5">
                {speakers()}
                <div className="with-border-bottom"/>
                <h6 className="ml-md-3 mt-5 mt-lg-0 font-weight-bold">Sponsorzy warsztatu</h6>
                <Sponsors sponsors={workshop.sponsors} className={'ml-lg-3 justify-content-left'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HorizontalSponsors/>
    </>
  );
}

export default Workshop;
