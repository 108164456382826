import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { find, first, keys } from 'lodash';

dayjs.extend(isToday);

const currentCongressDay = (congressDays) => {
  const date = dayjs();
  const congressDay = find(congressDays, (event) => date.isSame(event.event_date, 'day'));

  return congressDay ? congressDay.id : first(keys(congressDays));
}

export default currentCongressDay;

