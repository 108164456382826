import React, { Fragment } from 'react';

const Products = ({products}) => {
  const buildProductsList = () => {
    const list = []
    products.map((product) => list.push(<li className="mx-4 d-flex flex-column text-center justify-content-between flex-even mb-4" key={product.id}>
      <figure className="flex-grow-1">
        <img src={product.image_url} alt={product.title} className="rounded-lg" />
      </figure>
      <h2 className="h6 py-3">{product.title}</h2>
      <div className="link-wrapper">
        <a href={product.external_url} target="_marketplace" data-track={product.ahoy_track_data}
           className="btn d-inline-block btn-grey-outline px-lg-4 sponsor-product-link">Sprawdź</a>
      </div>
    </li>))
    return list;
  }

  return (
    <Fragment>
      {buildProductsList()}
    </Fragment>
  );
}

export default Products;
