const apiFetch = async (uri, setError, setPayload) => {
  const res = await fetch(`/api/v1/${uri}`);
  res
    .json()
    .then(res => setPayload(res))
    .catch(err => setError(err));
}

export const apiPost = async (uri, csrf, data = {}, setError, setPayload) => {
  const response = await fetch(uri, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response
    .json()
    .then(res => setPayload(res))
    .catch(err => setError(err));
}

export default apiFetch;
