export const summitCountDown = () => {
  const starting_at = Date.parse($('#countdown_starting_at').text());
  const finishing_at = Date.parse($('#countdown_finishing_at').text());

  const congressDurationState = (starting_at, finishing_at) => {
    const now = new Date().getTime();
    switch(true) {
      case (starting_at <= now && finishing_at >= now):
        return 'between';
      case (now < starting_at):
        return 'before';
      case (now > finishing_at):
        return 'after';
    }
  }

  const countDown = (starting_at) => {
    const now = new Date().getTime();
    const distance = starting_at - now;

    let content = '';
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    if (days === 0 && hours === 0 && minutes <= 1) {
      content = 'około 1 minutę';
    } else if (distance > 0) {
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      content = `
      <div class="days">${days} <span>${days === 1 ? 'dzień' : 'dni'}</span></div>
      <div class="colon pl-3">:</div>
      <div class="hours">${hours} <span>godz.</span></div>
      <div class="colon">:</div>
      <div class="minutes">${minutes} <span>min.</span></div>`;
    } else {
      content = '';
    }
    $('#countdown').html(content);
    return distance;
  }

  const summitInterval = setInterval(function run() {
    switch(congressDurationState(starting_at, finishing_at)) {
      case 'before':
        $('#player-countdown h5').text('Wydarzenie rozpocznie się za');
        countDown(starting_at);
        break;
      case 'after':
        clearInterval(summitInterval);
        $('#player-countdown h5').addClass('pt-5').text('Wydarzenie się skończyło');
        break;
      case 'between':
        clearInterval(summitInterval);
        $('#player-countdown').remove();
        $('#player').removeClass('d-none');
        break;
    }
    return run;
  }(), 30000, starting_at, finishing_at);
}
