import React, { Fragment, useContext } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { keys, first, isEmpty, values } from 'lodash';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import CongressContext from '../CongressContext';
import { useAppointmentsStore } from '../rootStoreProvider'
import formatDate from '../utils/formatDate';

const CurrentAppointment = observer(() => {

  const {congressSlug, sections, workshops} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const currentSection = sections[sectionSlug];
  const store = useAppointmentsStore();

  if (!currentSection) {
    return <Redirect to={`/${congressSlug}/${first(keys(sections))}`} />
  }

  const showButton = () => (
    store.watchNow()
      ?
      <Link to={`/${congressSlug}/${sectionSlug}/appointment`}
            className="appointment--btn btn btn-grey-outline" data-kongres={congressSlug}>Oglądaj</Link>
      :
      <span className="appointment--btn btn btn-grayed no-wrap">{store.isBefore() ? 'od' : 'do'} {store.currentDay.event_date}</span>
  )

  if (isEmpty(store.currentAppointment)) return null;
  const sectionProgram = () => (
    <dl className={clsx('current-appointment d-md-flex')}>
      <dt className="mr-md-3 mb-2 text-nowrap">
        {showButton()}
      </dt>
      <dd>
        <p className="current-event--description">{store.currentAppointment.title}</p>
        <p className="current-event--timing">{formatDate([store.currentDay.event_date, `${store.currentAppointment.start_time}`].join('T'), 'HH:mm')} - {formatDate([store.currentDay.event_date, `${store.currentAppointment.finish_time}`].join('T'), 'HH:mm')}</p>
      </dd>
    </dl>
  )

  const workshopsProgram = () => {
    const list = values(workshops).reverse().map(workshop => (
      <Fragment key={workshop.id}>
        <dl className={clsx('current-workshops d-md-flex align-items-center')}>
          <dt className="mr-md-3 mb-2 text-nowrap">
            {store.date?.isBetween(workshop.start_time, workshop.finish_time, 'minute', '[]')
              ?
              <Link to={`/${congressSlug}/${sectionSlug}/workshops/${workshop.slug}`}
                    className="workshop--btn btn btn-grey-outline" data-kongres={congressSlug}>Oglądaj</Link>
              :
              <span
                className="workshop--btn btn btn-grayed no-wrap">{formatDate(workshop.start_time, 'D.MM.YYYY HH:mm')}</span>
            }
          </dt>
          <dd>
            <p className="current-event--description mb-0">{workshop.title}</p>
          </dd>
        </dl>
      </Fragment>
      ))
    return (<>{list}</>)
  }

  return (
    <Fragment>
      {store.currentCongressTab === 'workshops' ? workshopsProgram() : sectionProgram()}
    </Fragment>
  )
})

export default CurrentAppointment;
