import React, { useContext, useState, useEffect } from 'react';
import CongressContext from '../CongressContext';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import apiFetch from '../utils/apiFetch';
import Slider from 'react-slick';
import currentCongressDay from "../utils/currentCongressDay";
import { compact, flatten, filter, isEmpty } from 'lodash';
import isMobile from '../utils/isMobile';
import '../../../stylesheets/sponsor.scss';
import '../../../stylesheets/partners_carousel.scss';

const RANKS = ['Sponsor platynowy', 'Sponsor złoty', 'Sponsor srebrny', 'Sponsor'];
const HorizontalSponsors = () => {
  const {congressSlug, sections} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const section = sections[sectionSlug];
  const [hasError, setError] = useState(false);
  const [sponsorList, setSponsorList] = useState({});

  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/sponsors`, setError, setSponsorList);
  }, [sectionSlug]);

  const congressDay = currentCongressDay(section.congress_days);
  const settings = {
    dots: false,
    draggable: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autookaySpeed: 2000,
    cssEase: 'ease-in',
    variableWidth: true,
    pauseOnHover: false,
    pauseOnFocus: false,
  };

  const buildRanks = () => {
    const items = RANKS.map((rank, index) => buildLogoList(rank, index));

    return <Slider {...settings}>
      {flatten(compact(items))}
    </Slider>
  }

  const buildLogoList = (rankName, rank) => {
    const filteredSponsors = filter(sponsorList[congressDay], (sponsor) => sponsor.rank === rank);
    if (isEmpty(filteredSponsors)) return null;

    const items = []
    if (!isMobile()) items.push(<div className='text-nowrap sponsor--rank-name' key={rank}>{rankName}</div>);

    filteredSponsors.map((sponsor, index) => (
      items.push(<div className='slide logos px-3' key={index}>{sponsorLogo(sponsor)}</div>)
    ));

    return items;
  }

  const sponsorLogo = sponsor => (
    sponsor.has_room ?
      <Link to={`/${congressSlug}/${sectionSlug}/sponsors/${sponsor.slug}`} className="sponsor--logo-link rank-0" target='_blank'>
        <img className="sponsor--logo" alt={sponsor.name} src={sponsor.avatar}/>
      </Link>
      :
      <img className="sponsor--logo" alt={sponsor.name} src={sponsor.avatar}/>
  )

  return (
    <div className="sponsors light-bg mt-auto">
      <div id='partners-carousel'>
        {buildRanks()}
      </div>
    </div>
  );
}

export default HorizontalSponsors;
